import { action, computed, observable } from "mobx";
import {
  District,
  EnumDriveDriveType,
  EnumInviteStatus,
  EnumUserDistrictRoles,
  EnumUserLanguage,
  Invite,
} from "../__generated__/graphql";
import AiToolParticipant, { EnumAiToolType } from "./AiToolParticipant";
import BotConversationParticipant from "./BotConversationParticipant";
import Attribute from "./decorators/Attribute";
import DeletableModel from "./DeletableModel";

export type IntegrationProperties = {
  [key: string]: string;
};

export enum IntegrationProvider {
  GOOGLE_CALENDAR = "google-calendar",
  GOOGLE_DRIVE = "google-drive",
  ONE_DRIVE = "one-drive",
  GOOGLE_CLASSROOM = "google-classroom",
  CANVAS = "canvas",
  MOODLE = "moodle",
  SCHOOLOGY = "schoology",
  BLACKBAUD = "blackbaud",
}

export enum PricingPlanTier {
  Basic = "basic",
  Pro = "pro",
  Enterprise = "enterprise",
}

const calculateDaysLeft = (startDate: string) => {
  const freeTrialDuration = Number(
    import.meta.env.VITE_APP_PRO_PLAN_TRIAL_DURATION || "14"
  );

  const onboardedDate = new Date(startDate);
  const trialEnd = new Date(onboardedDate.getTime());
  trialEnd.setDate(trialEnd.getDate() + freeTrialDuration);
  const today = new Date();
  const remaining = Math.ceil(
    (trialEnd.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)
  );
  const remainingDays = remaining > 0 ? remaining : 0;
  return remainingDays;
};

const calculateDaysRemaining = (trialEnd: string) => {
  const today = new Date();
  const remaining = Math.ceil(
    (new Date(trialEnd).getTime() - today.getTime()) / (1000 * 60 * 60 * 24)
  );
  return Math.max(remaining, 0);
};

class User extends DeletableModel {
  @Attribute
  @observable
  id: string;

  @Attribute
  @observable
  email: string;

  @Attribute
  @observable
  username: string;

  @Attribute
  @observable
  firstName: string;

  @Attribute
  @observable
  lastName: string;

  @Attribute
  @observable
  avatarUrl: string | null;

  @Attribute
  @observable
  isOnboarded: boolean | null;

  @Attribute
  @observable
  userRole: string | null;

  @Attribute
  @observable
  onboardedAt: string | null;

  @Attribute
  @observable
  verifiedAt: string | null;

  @Attribute
  @observable
  freeTrialStart: string | null;

  @Attribute
  @observable
  freeTrialEnd: string | null;

  @Attribute
  @observable
  copilotFreeTrialEnd: string | null;

  @Attribute
  @observable
  districtRoles: EnumUserDistrictRoles[] | null;

  @Attribute
  @observable
  district: District | null;

  @observable
  authProvider: string;

  @computed
  get fullName(): string {
    return this.firstName + " " + this.lastName;
  }

  @computed
  get initial(): string {
    return this.firstName[0];
  }

  @observable
  integrations: { [key in IntegrationProvider]?: IntegrationProperties } | null;

  @observable
  aiToolParticipants: AiToolParticipant[] | null;

  @observable
  botConversationParticipants: BotConversationParticipant[] | null;

  @observable
  invites: Invite[] | null;

  @observable
  chatCount: number;

  @observable
  toolCount: number;

  @observable
  instantResourcesCount: number;

  @observable
  imageGeneratorCount: number;

  @observable
  enterpriseUser: boolean;

  @observable
  invitedCollaborators: boolean;

  @observable
  teamMemberCount: number;

  @Attribute
  @observable
  language: EnumUserLanguage | null;

  @Attribute
  @observable
  subscriptionInterval?: "Monthly" | "Annually" | null;

  @Attribute
  @observable
  subscriptionType?: "Copilot" | "Slides" | "CopilotSlidesBundle" | null;

  @Attribute
  @observable
  enterprisePlan?: {
    isEnterpriseAccount: boolean;
    isLicenseActive: boolean;
    subscriptionType: string;
  } | null;

  @Attribute
  @observable
  referralActionsPending?: {
    useChat: boolean;
    finishOnboarding: boolean;
    createSlideshow: boolean;
  } | null;

  // We are storing link processing status for tools in the user model
  @observable
  processLinksMap: {
    [key: string]: {
      id: string;
      assetId: string;
      logs: string[];
      status: string;
      error?: string | null;
      aiFlaggedContent?: boolean | null;
      finalOutput?: string | null;
    };
  } = {};

  // We are storing differentiated resource generator init status model
  @observable
  differentiatedResourcesInitModelMap: {
    [key: string]: {
      id: string;
      processedCount: number;
      totalCount: number;
      logs: string[];
      status: string;
      error?: string | null;
      aiFlaggedContent?: boolean | null;
    };
  } = {};

  @action
  setProcessLinkStatus(
    id: string,
    assetId: string,
    logs: string[],
    status: string,
    error?: string | null,
    aiFlaggedContent?: boolean | null,
    finalOutput?: string | null
  ) {
    let updateProcessLinksMap = {
      ...this.processLinksMap,
    };

    if (updateProcessLinksMap[id]) {
      updateProcessLinksMap[id] = {
        ...updateProcessLinksMap[id],
        assetId,
        logs,
        status,
        error,
        aiFlaggedContent,
        finalOutput,
      };
    } else {
      updateProcessLinksMap[id] = {
        id,
        assetId,
        logs,
        status,
        error,
        aiFlaggedContent,
        finalOutput,
      };
    }

    this.processLinksMap = updateProcessLinksMap;
  }

  @action
  removeProcessLinkStatus(id: string) {
    let updateProcessLinksMap = {
      ...this.processLinksMap,
    };

    if (updateProcessLinksMap[id]) {
      delete updateProcessLinksMap[id];
    }

    this.processLinksMap = updateProcessLinksMap;
  }

  // We are storing differentiated resource generator init status model
  @action
  setDifferentiatedResourcesToolInit(
    id: string,
    processedCount: number,
    totalCount: number,
    logs: string[],
    status: string,
    error?: string | null,
    aiFlaggedContent?: boolean | null
  ) {
    let updateDifferentiatedResourceMap = {
      ...this.differentiatedResourcesInitModelMap,
    };

    if (updateDifferentiatedResourceMap[id]) {
      updateDifferentiatedResourceMap[id] = {
        ...updateDifferentiatedResourceMap[id],
        processedCount,
        totalCount,
        logs,
        status,
        error,
        aiFlaggedContent,
      };
    } else {
      updateDifferentiatedResourceMap[id] = {
        id,
        processedCount,
        totalCount,
        logs,
        status,
        error,
        aiFlaggedContent,
      };
    }

    this.differentiatedResourcesInitModelMap = updateDifferentiatedResourceMap;
  }

  @action
  removeDifferentiatedResourcesToolInit(id: string) {
    let updateDifferentiatedResourceMap = {
      ...this.differentiatedResourcesInitModelMap,
    };

    if (updateDifferentiatedResourceMap[id]) {
      delete updateDifferentiatedResourceMap[id];
    }

    this.differentiatedResourcesInitModelMap = updateDifferentiatedResourceMap;
  }

  // Excludes check for free trial
  @computed
  get isSubscribedToPlan() {
    // LEGACY PRO PLAN EXCEPTIONS (KEEP FOR BACKWARD COMPATIBILITY for Now)
    const proPlanExceptions = JSON.parse(
      import.meta.env.VITE_APP_PRO_PLAN_EXCEPTIONS || "[]"
    );

    if (proPlanExceptions.includes(this.email)) {
      return true;
    }

    // LEGACY SUBSCRIPTION CHECK for PRO PLAN (KEEP FOR BACKWARD COMPATIBILITY for Now)
    if (
      this.district &&
      this.district.payments &&
      !this.district.payments.enterprisePlan
    ) {
      const status = this.district.payments.status;
      const activeUsers = this.district.payments.activeUsers;

      if (status === "paid" && activeUsers && activeUsers.includes(this.id)) {
        return true;
      }
    }

    // V2
    // First we check the enterprise subscription
    if (
      this.enterprisePlan &&
      this.enterprisePlan.isLicenseActive &&
      (this.enterprisePlan.subscriptionType === "Copilot" ||
        this.enterprisePlan.subscriptionType === "CopilotSlidesBundle")
    ) {
      return true;
    }

    // Then we check the individual subscription
    if (
      this.subscriptionType &&
      (this.subscriptionType === "CopilotSlidesBundle" ||
        this.subscriptionType === "Copilot")
    ) {
      return true;
    }

    return false;
  }

  // Includes check for free trial
  @computed
  get currentPlan(): PricingPlanTier {
    const proPlanExceptions = JSON.parse(
      import.meta.env.VITE_APP_PRO_PLAN_EXCEPTIONS || "[]"
    );

    // LEGACY PRO PLAN EXCEPTIONS (KEEP FOR BACKWARD COMPATIBILITY for Now)
    if (proPlanExceptions.includes(this.email)) {
      return PricingPlanTier.Pro;
    }

    // LEGACY SUBSCRIPTION CHECK for PRO PLAN (KEEP FOR BACKWARD COMPATIBILITY for Now)
    if (
      this.district &&
      this.district.payments &&
      !this.district.payments.enterprisePlan
    ) {
      const status = (this.district.payments as any).status;
      const activeUsers = (this.district.payments as any).activeUsers;

      if (status === "paid" && activeUsers && activeUsers.includes(this.id)) {
        return PricingPlanTier.Pro;
      }
    }

    // Free Trial Check
    if (
      !this.freeTrialEnd &&
      this.freeTrialStart &&
      calculateDaysLeft(this.freeTrialStart) > 0
    ) {
      return PricingPlanTier.Pro;
    }

    // Referral Free Trial Check (copilotFreeTrialEnd - current date)
    if (
      this.copilotFreeTrialEnd &&
      calculateDaysRemaining(this.copilotFreeTrialEnd) > 0
    ) {
      return PricingPlanTier.Pro;
    }

    // V2
    // First we check the enterprise subscription
    if (
      this.enterprisePlan &&
      this.enterprisePlan.isLicenseActive &&
      (this.enterprisePlan.subscriptionType === "Copilot" ||
        this.enterprisePlan.subscriptionType === "CopilotSlidesBundle")
    ) {
      return PricingPlanTier.Enterprise;
    }

    // Then we check the individual subscription
    if (
      this.subscriptionType &&
      (this.subscriptionType === "CopilotSlidesBundle" ||
        this.subscriptionType === "Copilot")
    ) {
      return PricingPlanTier.Pro;
    }

    return PricingPlanTier.Basic;
  }

  @computed
  get doesWorkspaceHavePaidSeats() {
    let paid = false;
    if (this.district && this.district.payments) {
      const status = this.district.payments.status;
      const activeUsers = this.district.payments.activeUsers;
      if (status === "paid" && activeUsers && activeUsers.length > 0) {
        paid = true;
      }
    }
    return paid;
  }

  @computed
  get isWorkspaceOwner() {
    let isOwner = false;
    if (
      this.district &&
      this.district.createdBy &&
      this.district.createdBy.id === this.id
    ) {
      isOwner = true;
    }
    return isOwner;
  }

  @computed
  get canManageWorkspace() {
    let isOwner = false;
    if (
      this.district &&
      this.district.createdBy &&
      this.district.createdBy.id === this.id
    ) {
      isOwner = true;
    } else if (
      this.districtRoles &&
      (this.districtRoles.includes(EnumUserDistrictRoles.Administrator) ||
        this.districtRoles.includes(
          EnumUserDistrictRoles.DistrictAdministrator
        ))
    ) {
      isOwner = true;
    }
    return isOwner;
  }

  @computed
  get numberOfCurriculumToolUses() {
    const { aiToolParticipantsStore } = this.store.rootStore;

    if (!aiToolParticipantsStore) {
      return 0;
    }

    const participants: AiToolParticipant[] =
      aiToolParticipantsStore.sortedData.filter(
        (participant: AiToolParticipant) =>
          participant.userId === this.id &&
          participant.owner &&
          participant.aiToolType === EnumAiToolType.CURRICULUM_CREATOR
      );

    let toolUses = 0;

    participants.map((participant) => {
      if (participant.owner) {
        toolUses += 1;
      }
    });
    return toolUses;
  }

  @computed
  get numberOfInstantResourceUses() {
    const { aiToolParticipantsStore } = this.store.rootStore;

    if (!aiToolParticipantsStore) {
      return 0;
    }

    const participants: AiToolParticipant[] =
      aiToolParticipantsStore.sortedData.filter(
        (participant: AiToolParticipant) =>
          participant.userId === this.id &&
          participant.owner &&
          participant.aiToolType === EnumAiToolType.INSTANT_RESOURCES
      );

    let toolUses = 0;

    participants.map((participant) => {
      if (participant.owner) {
        toolUses += 1;
      }
    });
    return toolUses;
  }

  @computed
  get numberOfImageGeneratorUses() {
    const { aiToolParticipantsStore } = this.store.rootStore;

    if (!aiToolParticipantsStore) {
      return 0;
    }

    const participants: AiToolParticipant[] =
      aiToolParticipantsStore.sortedData.filter(
        (participant: AiToolParticipant) =>
          participant.userId === this.id &&
          participant.owner &&
          participant.aiToolType === EnumAiToolType.IMAGE_GENERATOR
      );

    let toolUses = 0;

    participants.map((participant) => {
      if (participant.owner) {
        toolUses += 1;
      }
    });

    return toolUses;
  }

  @computed
  get numberOfChatUses() {
    const { conversationParticipants } = this.store.rootStore;

    if (!conversationParticipants) {
      return 0;
    }

    const participants: BotConversationParticipant[] =
      conversationParticipants.sortedData.filter(
        (participant: BotConversationParticipant) =>
          participant.user.id === this.id && participant.owner
      );

    let chatUses = 0;

    participants.map((participant) => {
      if (participant.owner) {
        chatUses += 1;
      }
    });
    return chatUses;
  }

  // Calculate the number of referrals after the cut off date
  @computed
  get numberOfReferrals() {
    let acceptedInvites = 0;
    const cutOffDate = new Date("2023-11-14");
    this.invites?.forEach((invite) => {
      if (
        invite.status === EnumInviteStatus.Accepted &&
        new Date(invite.createdAt) > cutOffDate
      ) {
        acceptedInvites += 1;
      }
    });
    return acceptedInvites;
  }

  // Calculate the number of referrals before the cut off date
  @computed
  get numberOfReferralsLegacy() {
    let acceptedInvites = 0;
    const cutOffDate = new Date("2023-11-14");
    this.invites?.forEach((invite) => {
      if (
        invite.status === EnumInviteStatus.Accepted &&
        new Date(invite.createdAt) <= cutOffDate
      ) {
        acceptedInvites += 1;
      }
    });
    return acceptedInvites;
  }

  // Now do the same for chat uses
  @computed
  get chatUsesAllowed() {
    const version2ReleaseDate = new Date("2023-12-12");

    // If the user signed up before the version 2 release date, they get 5 free chat uses
    const freeChatUsesV2 =
      new Date(this.createdAt) <= version2ReleaseDate ? 5 : 0;

    return (
      Number(import.meta.env.VITE_APP_FREE_CHAT_USES) +
      freeChatUsesV2 +
      this.numberOfReferrals *
        Number(import.meta.env.VITE_APP_REFERRAL_CHAT_USES)
    );
  }

  @computed
  get numberOfChatUsesLeft() {
    let usesLeft = this.chatUsesAllowed - this.numberOfChatUses;

    if (usesLeft < 0) {
      usesLeft = 0;
    }

    return usesLeft;
  }

  // Calculate the total number of tool uses allowed (Free plan + Referrals)
  @computed
  get toolUsesAllowed() {
    const cutOffDate = new Date("2023-11-14");

    const version2ReleaseDate = new Date("2023-12-12");

    // If the user signed up before the version 2 release date, they get 5 free chat uses
    const freeToolUsesV2 =
      new Date(this.createdAt) <= version2ReleaseDate ? 5 : 0;

    const freeToolUses =
      new Date(this.createdAt) <= cutOffDate
        ? Number(import.meta.env.VITE_APP_FREE_TOOL_USES_LEGACY)
        : Number(import.meta.env.VITE_APP_FREE_TOOL_USES);

    return (
      freeToolUses +
      this.numberOfReferralsLegacy *
        Number(import.meta.env.VITE_APP_REFERRAL_TOOL_USES_LEGACY) +
      freeToolUsesV2 +
      this.numberOfReferrals *
        Number(import.meta.env.VITE_APP_REFERRAL_TOOL_USES)
    );
  }

  // Calculate the number of tool uses left (Total uses allowed - Number of tool uses)
  @computed
  get numberOfCurriculumToolUsesLeft() {
    let usesLeft = this.toolUsesAllowed - this.numberOfCurriculumToolUses;

    if (usesLeft < 0) {
      usesLeft = 0;
    }

    return usesLeft;
  }

  // Calculate the total number of instant resources uses allowed (Free plan + Referrals)
  @computed
  get instantResourceUsesAllowed() {
    const freeToolUses = Number(
      import.meta.env.VITE_APP_FREE_INSTANT_RESOURCE_USES
    );

    return (
      freeToolUses +
      this.numberOfReferrals *
        Number(import.meta.env.VITE_APP_REFERRAL_INSTANT_RESOURCE_USES)
    );
  }

  // Calculate the number of instant resource uses left (Total uses allowed - Number of instant resource uses)
  @computed
  get numberOfInstantResourceUsesLeft() {
    let usesLeft =
      this.instantResourceUsesAllowed - this.numberOfInstantResourceUses;

    if (usesLeft < 0) {
      usesLeft = 0;
    }

    return usesLeft;
  }

  // Calculate the total number of instant resources uses allowed (Free plan + Referrals)
  @computed
  get imageGeneratorUsesAllowed() {
    const freeToolUses = Number(
      import.meta.env.VITE_APP_FREE_IMAGE_GENERATOR_USES
    );

    return (
      freeToolUses +
      this.numberOfReferrals *
        Number(import.meta.env.VITE_APP_REFERRAL_IMAGE_GENERATOR_USES)
    );
  }

  // Calculate the number of instant resource uses left (Total uses allowed - Number of instant resource uses)
  @computed
  get numberOfImageGeneratorUsesLeft() {
    let usesLeft =
      this.imageGeneratorUsesAllowed - this.numberOfImageGeneratorUses;

    if (usesLeft < 0) {
      usesLeft = 0;
    }

    return usesLeft;
  }

  @computed
  get defaultDriveId(): string | null {
    const { district } = this;

    if (district && district.drives && district.drives.length > 0) {
      // Find the drive with driveType === EnumDriveDriveType.WorkspaceDefault
      const defaultDrive = district.drives.find(
        (drive) => drive.driveType === EnumDriveDriveType.WorkspaceDefault
      );

      if (defaultDrive) {
        return defaultDrive.id;
      }

      return null;
    }

    return null;
  }

  @computed
  get freeTrialDaysLeft() {
    if (this.copilotFreeTrialEnd) {
      return calculateDaysRemaining(this.copilotFreeTrialEnd);
    }

    if (!this.freeTrialStart || this.freeTrialEnd) return 0;

    return calculateDaysLeft(this.freeTrialStart);
  }
}

export default User;
